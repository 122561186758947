/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/spip/SPIP@3.2.19/prive/javascript/jquery.form.min.js
 * - /gh/spip/SPIP@3.2.19/prive/javascript/jquery.autosave.min.js
 * - /gh/spip/SPIP@3.2.19/prive/javascript/jquery.placeholder-label.min.js
 * - /gh/spip/SPIP@3.2.19/prive/javascript/ajaxCallback.min.js
 * - /gh/spip/SPIP@3.2.19/prive/javascript/js.cookie.min.js
 * - /gh/spip/SPIP@3.2.19/prive/javascript/jquery.cookie.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
